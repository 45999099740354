import { BlackBox } from "@wayflyer/blackbox-fe";
import {
  ErrorComponentProps,
  Flex,
  FlyErrorComponent,
  FlyUIProvider,
  Link,
  Logo,
  Text,
} from "@wayflyer/flyui";
import { PropsWithChildren } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { PACKAGE_VERSION } from "../utils/env";
import { isProd } from "../utils/host";

import { Crowdin } from "./scripts/Crowdin";
import { GTM } from "./scripts/GTM";

const CROWDIN_KEY = "crowdin_editor";

type CodeOwner = "platform";

export const displayOwners: Record<CodeOwner, string> = {
  platform: "S02UJRQUTRA", // @@platform-team
};

const AuthPathOwners: Record<string, string> = {
  "/:wildcard*/": "platform",
};

export const ErrorComponent = (props: ErrorComponentProps) => {
  return (
    <Flex
      direction="column"
      padding="6"
      minHeight="100vh"
      minWidth="100%"
      gap="16"
    >
      <Link url="/">
        <Flex maxWidth="200px">
          <Logo type="auth" />
        </Flex>
      </Link>
      <FlyErrorComponent {...props} />
    </Flex>
  );
};

interface PluginConfig {
  gtm?: Parameters<typeof GTM>[0];
  crowdin?: Parameters<typeof Crowdin>[0];
  datadog: {
    applicationId: string;
    clientToken: string;
    service: string;
    site: string;
    [key: string]: boolean | string | number | Array<string>;
  };
}

type PluginsProps = PropsWithChildren<PluginConfig>;

export const Plugins = ({ children, ...config }: PluginsProps) => {
  const intl = useIntl();

  const urlParams = new URLSearchParams(location.search);
  const crowdinEnabled = urlParams.get(CROWDIN_KEY) === "true";

  const observabilityConfigs = {
    datadog: {
      ...config.datadog,
      sessionSampleRate: isProd ? 100 : 20,
      sessionReplaySampleRate: isProd ? 100 : 0,
      version: `${PACKAGE_VERSION}`,
    },
  };

  return (
    <>
      {config.gtm ? <GTM {...config.gtm} /> : null}
      {crowdinEnabled && config.crowdin ? (
        <Crowdin {...config.crowdin} />
      ) : null}

      <BlackBox
        viewPathsOwners={AuthPathOwners}
        displayOwners={displayOwners}
        observabilityConfigs={observabilityConfigs}
        FallbackComponent={({ resetErrorBoundary }) => (
          <FlyUIProvider theme="wayflyer">
            <ErrorComponent
              {...{
                title: intl.formatMessage({
                  id: "auth: error unknown default title",
                  defaultMessage: "Sorry, we’ve encountered an error",
                }),
                message: (
                  <FormattedMessage
                    id="auth: error unknown default message"
                    defaultMessage="<p>We’re working hard to get this fixed. Please go back or try refreshing the page. For more information contact our support team.</p>"
                    values={{
                      p: (chunks) => <Text>{chunks}</Text>,
                    }}
                  />
                ),
                primaryAction: {
                  content: intl.formatMessage({
                    id: "Go back",
                    defaultMessage: "Go back",
                  }),
                  onAction: resetErrorBoundary,
                },
              }}
            />
          </FlyUIProvider>
        )}
      >
        {children}
      </BlackBox>
    </>
  );
};
