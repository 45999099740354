import { BrowserRouter, useRoutes } from "react-router";
import { createRoot } from "react-dom/client";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import { FlyUIProvider } from "@wayflyer/flyui";
import React from "react";

import English from "../compiled-lang/en.json";
import German from "../compiled-lang/de.json";
import Spanish from "../compiled-lang/es.json";
import thirdPartyConfig from "../configs/config.third-party.json";

import { Plugins } from "./plugins/Plugins";
import Auth0App from "./Auth0App";
import { FallbackRedirect } from "./components/FallbackRedirect";
import { WfPostHogProvider } from "./components/PostHogProvider";

const getLocale = () => navigator.language;

const loadMessages = () => {
  const locale = getLocale();
  if (locale.startsWith("es")) {
    return Spanish;
  } else if (locale.startsWith("de")) {
    return German;
  }
  return English;
};

const intl = createIntl(
  {
    messages: loadMessages(),
    locale: getLocale(),
    defaultLocale: "en",
  },
  createIntlCache(),
);

const Root = () => {
  const pluginsConfig = (thirdPartyConfig as any)[location.hostname];

  const rendered = useRoutes([
    {
      path: "/",
      element: <FallbackRedirect to="/authn/login" />,
    },
    {
      path: "/a/*",
      element: <FallbackRedirect to="/authn/login" />,
    },
    {
      path: "/login",
      element: <FallbackRedirect to="/authn/login" keepSearch />,
    },
    {
      path: "/logout",
      element: <FallbackRedirect to="/authn/logout" keepSearch />,
    },
    {
      path: "/signup",
      element: <FallbackRedirect to="/authn/signup" keepSearch />,
    },
    {
      path: "/authn/*",
      element: <Auth0App />,
    },
  ]);

  return (
    <WfPostHogProvider>
      <RawIntlProvider value={intl}>
        <Plugins {...pluginsConfig}>
          <FlyUIProvider theme="wayflyer" intl={intl}>
            {rendered}
          </FlyUIProvider>
        </Plugins>
      </RawIntlProvider>
    </WfPostHogProvider>
  );
};

const renderApp = () => {
  const container = document.getElementById("root");
  if (!container) {
    throw new Error("Root element not found");
  }

  createRoot(container).render(
    <React.StrictMode>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </React.StrictMode>,
  );
};

renderApp();
