import { logger } from "@wayflyer/blackbox-fe";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";

export interface FallbackRedirectProps {
  to: string;
  keepSearch?: boolean;
}

export const FallbackRedirect = ({
  to,
  keepSearch = false,
}: FallbackRedirectProps): JSX.Element => {
  const location = useLocation();
  logger.warn(`fallback redirect: ${location.pathname} -> ${to}`, {
    from: location.pathname,
    to,
  });

  const navigate = useNavigate();
  useEffect(() => {
    navigate(
      {
        pathname: to,
        search: keepSearch ? location.search : undefined,
      },
      { replace: true },
    );
  }, [navigate, to, keepSearch, location.search]);

  return <></>;
};
